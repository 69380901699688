<template>
  <v-row align="center" justify="center">
    <div class="backgruond"></div>
    <div class="div-inline2"></div>
    <v-snackbar top color="green" v-model="snackbar">
      Login success
    </v-snackbar>
    <v-snackbar top color="red" v-model="showAlert">
      {{ message }}
    </v-snackbar>
    <v-col cols="12">
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 12 : 6"
          class="mx-auto"
          height="520"
          max-width="400"
        >
          <div class="pt-12 mx-auto text-center">
            <v-avatar size="100" color="indigo lighten-4">
              <v-icon size="40" color="indigo">mdi-account</v-icon>
            </v-avatar>
            <h3 class="pt-2 indigo--text">隐身安全网络管理平台</h3>
          </div>
          <v-card-text class="my-4 text-center text-h6">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="username"
                :counter="10"
                :rules="nameRules"
                label="账户名(邮箱)"
                prepend-inner-icon="mdi-account"
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                label="密码"
                required
                :type="passwordShow ? 'text' : 'password'"
                prepend-inner-icon="mdi-key"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
                @keyup.enter="login"
              ></v-text-field>

              <v-checkbox
                v-model="rememberMe"
                label="记住我?"
                @change="setRememberMe(rememberMe)"
              ></v-checkbox>

              <v-btn color="indigo" @click="login" :loading="loading" block>
                <span class="white--text px-8">Login</span>
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    valid: true,
    loading: false,
    snackbar: false,
    username: "",
    password: "",
    passwordShow: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    passwordRules: [
      (v) => !!v || " is required",
      (v) =>
        (v && v.length <= 15) || "Password must be less than 15 characters",
    ],
    rememberMe: false,
    message: "",
    showAlert: false,
  }),

  methods: {
    ...mapMutations(["setAuth", "setRememberMe"]),

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    login() {
      this.auth();
    },
    async auth() {
      this.loading = true;
      let url = `/trunkserver/accounts/api-token-auth/`;
      await this.$axios
        .post(url, {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          this.setAuth(res.data);
          this.$router.push("/");
          this.loading = false;
          this.snackbar = true;
          this.$message.success("登陆成功");
        })
        .catch((error) => {
          let msg = [];
          let items = error.data;
          for (let key in items) {
            msg.push(items[key]);
            console.log(items[key]);
          }
          this.message = String(msg);
          this.loading = false;
          this.showAlert = true;
          this.$message.error(this.message);
        });
    },
    initData() {
      this.rememberMe = this.$store.getters.rememberMe;
    },
  },

  mounted: function () {
    this.initData();
  },

  watch: {
    message: {
      handler(newVal, oldVal) {
        // ...
        if (newVal !== "") {
          this.showAlert = true;
          console.log(oldVal);
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.backgruond {
  background-image: url(../../assets/image/auth/banner.jpg) !important;
  height: 45%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
}
.div-inline {
  position: absolute;
  top: 480px;
  display: inline-flex;
  background-color: rgba(205, 193, 197, 0.4);
  width: 100%;
  height: 100%;
  /* z-index: 0; */
}
.div-inline2 {
  position: absolute;
  top: 35%;
  display: inline-flex;
  background-color: rgba(228, 222, 222, 0.2);
  width: 100%;
  height: 65%;
  /* z-index: 0; */
}
</style>
